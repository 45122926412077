<template>
  <v-card class="px-7 rounded-xl pb-5" flat>
    <v-toolbar
      class="font-weight-bold d-flex justify-center"
      flat
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); padding: 0px"
    >
      AppSumo Lifetime Plan
      <v-chip class="secondary darken-1 ml-1 primary--text" small>
        {{ activeTier }}
      </v-chip>
    </v-toolbar>
    <div class="d-flex justify-space-between mt-2">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        {{ recordHours }} recording hours per month
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        {{ account }} {{ account > 1 ? "accounts" : "account" }} user
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        {{ user.payment.upgrade.storage }} GB storage
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small :style="withCustomDomain">
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >{{
            user.payment.upgrade.custom_domains !== 0
              ? "mdi-check"
              : "mdi-close"
          }}</v-icon
        >
        Custom branding and CNAME
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Video recording
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Video request link
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Share recorded videos with a link
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Instant notification
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Video management
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Team collaboration
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Download and export videos
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small>
        <v-icon
          class="mt-n1 mr-2"
          style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
          color="primary"
          >mdi-check</v-icon
        >
        Web-based (No installation needed)
      </small>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AppSumoPlans",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    activeTier() {
      let string = "";
      let account_type = this.user.account_type.split(" ");
      string = `${account_type[1]} ${account_type[2]}`;

      return string;
    },
    recordHours() {
      return this.user.payment.upgrade.minutes >= 1000000
        ? "Unlimited"
        : this.user.payment.upgrade.minutes / 60;
    },
    account() {
      return this.user.payment.upgrade.accounts;
    },
    withCustomDomain() {
      return this.user.account_type !== "AppSumo Tier 1"
        ? ""
        : "text-decoration: line-through";
    },
  },
  methods: {
    minutes(user) {
      return ["Pro Basic", "Pro Premium"].includes(user.account_type)
        ? "Unlimited"
        : user.minutes;
    },
  },
};
</script>
