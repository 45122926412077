<template>
  <v-card class="px-7 rounded-xl" flat>
    <v-toolbar class="font-weight-bold d-flex justify-center" flat>
      Personal Plan
    </v-toolbar>
    <v-chip class="secondary darken-1 mt-n2 primary--text" small>
      {{ user.account_type }}
    </v-chip>
    <div class="d-flex justify-space-between mt-3">
      <small class="font-weight-bold">Period Start</small>
      <small>
        {{
          user.payment ? new Date(user.payment.start_date).toDateString() : ""
        }}
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small class="font-weight-bold">Period End</small>
      <small>
        {{ user.payment ? new Date(user.payment.end_date).toDateString() : "" }}
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small class="font-weight-bold">Recording Minutes</small>
      <small>
        {{ user.minutes - user.minutes_left }} of {{ minutes(user) || 0 }} used
      </small>
    </div>
    <div class="d-flex justify-space-between mt-1">
      <small class="font-weight-bold">User Accounts</small>
      <small>
        {{ user.accounts_added || 0 }} of
        {{ user.total_accounts_available || 0 }} used
      </small>
    </div>
    <v-card-actions class="mt-4 pb-7">
      <v-spacer></v-spacer>
      <v-btn to="/account-upgrade" class="capitalize primary px-5" rounded>
        Upgrade plan
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "KonveyPlans",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    minutes(user) {
      return ["Pro Basic", "Pro Premium"].includes(user.account_type)
        ? "Unlimited"
        : user.minutes;
    },
  },
};
</script>
